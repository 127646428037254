<template>
  <div>
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">畜禽粪污收纳分析</div>
      </div>
      <div class="chart-box">
        <div ref="chart" class="chart"></div>
        <div class="unit">单位： {{ unit }}</div>
        <!-- <div class="unit">
        <div
          v-for="(item, i) in chooselist"
          :key="i"
          :class="item.isSelect ? 'btnactive' : 'btn'"
          @click="checkInfo(i)"
          style="cursor: pointer"
        >
          {{ item.text }}
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
//畜禽收纳
import * as echarts from "echarts";

export default {
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      // data: [],
      unit: "",
      chooselist: [
        { text: "日", id: "1", isSelect: false, type: "day" },
        { text: "月", id: "2", isSelect: false, type: "month" },
        { text: "年", id: "3", isSelect: true, type: "year" },
      ],
      currIndexRecommend: Number,
      datas: [],
      type: "",
    };
  },
  methods: {
    checkInfo(currIndexRecommend) {
      let id = this.chooselist[currIndexRecommend].id;
      let comboList = this.chooselist;
      for (let i = 0; i < comboList.length; i++) {
        comboList[i].isSelect = i == currIndexRecommend;
      }
      this.chooselist = comboList;
      this.type = this.chooselist[currIndexRecommend].type;
      this.getStaticss().then(() => {
        this.initChart();
      });
    },
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "rgba(12, 12, 13, 0.4)",
          borderColor: "rgba(12, 12, 13, 0.4)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#7E91AE",
              type: "solid",
            },
          },
          formatter: (params, ticket) => {
            let d = params[0];
            let icon = `<div style="display:flex;">${d.axisValue}年&nbsp;&nbsp;&nbsp;&nbsp;${d.data}${this.unit}</div>`;
            return `<div style="font-size:12px;font-weight:400;">${icon}</div>`;
          },
        },
        xAxis: {
          data: this.datas.map((item) => item.time),
          axisLabel: {
            color: "#fff",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#7E91AE"],
              type: "solid",
            },
          },
        },
        yAxis: {
          nameTextStyle: {
            color: "#fff",
          },
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ["#7E91AE"],
              type: "solid",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            smooth: true,
            type: "line",
            data: this.datas.map((item) => item.val),
            lineStyle: {
              color: "rgba(121, 54, 255, 1)",
            },
            itemStyle: {
              color: "rgba(121, 54, 255, 1)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(121, 54, 255, 0.67)",
                },
                {
                  offset: 0.8,
                  color: "rgba(182, 139, 21, 0)",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      this.datas = [];
      return this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 129,
      }).then((res) => {
        res.forEach((item) => {
          this.datas.push({
            time: item.dimensionName,
            val: item.content,
          });
        });
        this.unit = res[0].contentUnit;
      });
    },
    getStaticss() {
      this.datas = [];
      return this.$post(this.$api.STATICTICS_MAP.AMOUNT, {
        type: this.type,
      }).then((res) => {
        let timeLine = res.timeLine;
        for (let key in timeLine) {
          this.datas.push({
            time: key,
            val: timeLine[key],
          });
        }
      });
    },
  },
  mounted() {
    this.getStatics().then(() => {
      this.initChart();
    });
    // this.getStaticss().then(() => {
    //   this.initChart();
    // });
  },
};
</script>
<style lang="less" scoped>
@import url("../common.less");

.unit {
  position: absolute;
  width: 95%;
  left: 16px;
  top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  color: #b2bed1;
  font-size: 12px;

  .btn {
    width: 45px;
    height: 25px;
    border: 1px solid #343e4f;
    text-align: center;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .btnactive {
    width: 45px;
    height: 25px;
    border: 1px solid #00fff7;
    text-align: center;
    margin-right: 5px;
    color: #00fff7;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .right-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .right-bottom {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .left-bottom {
    width: 16px;
    height: 16px;
    border-bottom: 2px solid #00fff7;
    border-left: 2px solid #00fff7;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .left-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
</style>
